import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';

import useScreenRecognition from 'gatsby-theme-gaviscon/src/hooks/useScreenRecognition';
import GatsbyImage from 'gatsby-theme-gaviscon/src/components/common/GatsbyImage';
import { parseBoolean } from 'gatsby-theme-gaviscon/src/utils/parseHelpers';
import DangerouslySetInnerHtml from 'gatsby-theme-gaviscon/src/components/common/DangerouslySetInnerHtml';

import 'gatsby-theme-gaviscon/src/components/ProductBanner/ProductBanner.scss';
import './ProductBannerOverride.scss';

const ProductBanner: FC<ProductPageTypes.IProductBanner> = ({
  title,
  image,
  mobileImage,
  mobileImageAlt,
  imageAlt,
  logo,
  logoAlt,
  tags,
}) => {
  const colorTag = tags.find((tag) => parseBoolean(tag.isProductFamily));
  const cx = classnames({
    'product-banner': true,
    [`product-banner--${colorTag?.color?.label}`]: colorTag?.color?.label,
  });
  const { isMobile, isNotMobile } = useScreenRecognition();
  const hasLogo = title !== 'Gavirepair';

  return (
    <div className={cx} data-testid="product-banner">
      <Container fluid>
        <div className="product-banner__text">
          {hasLogo && logo?.fallbackUrl ? (
            <GatsbyImage
              isLazyLoading
              className="product-banner__logo"
              fluid={logo}
              alt={logoAlt}
            />
          ) : null}
          <DangerouslySetInnerHtml
            element="h1"
            className="product-banner__text-title"
            html={title}
          />
        </div>
        {isMobile && image?.fallbackUrl ? (
          <div className="product-banner__image-desktop">
            <GatsbyImage
              isLazyLoading={false}
              objectFit="contain"
              className="product-banner__image-img"
              fluid={mobileImage}
              alt={mobileImageAlt}
              style={{ position: 'absolute' }}
              fadeIn={false}
            />
          </div>
        ) : null}
        {isNotMobile && image?.fallbackUrl ? (
          <div className="product-banner__image-desktop">
            <GatsbyImage
              isLazyLoading={false}
              objectFit="contain"
              className="product-banner__image-img"
              fluid={image}
              alt={imageAlt}
              style={{ position: 'absolute' }}
            />
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default ProductBanner;
